// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.loader-container {
  min-height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}

small {
  font-size: 0.75em;
}

.loyalty-card {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23E3000E' stroke-width='6' stroke-dasharray='10%2c15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
  text-align: center;
  padding: 10px 0;
  width: 30%;
  max-width: 158px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.loyalty-card .loyalty-card-header {
  padding: 0 10px;
  font-size: 15px;
  color: #E3000E;
}
.loyalty-card .loyalty-card-content {
  padding: 0 10px;
  font-size: 12px;
}

.loyalty-card-disabled {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23E3000E' opacity='0.4' stroke-width='6' stroke-dasharray='10%2c15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
}

.paging-button {
  width: 24px;
}
.paging-button mat-icon {
  color: #E3000E;
  cursor: pointer;
}

@media screen and (max-width: 479px) {
  .loyalty-card {
    width: 40%;
  }
  .loyalty-card .loyalty-card-header {
    padding: 0 5px;
    font-size: 14px;
  }
  .loyalty-card .loyalty-card-content {
    padding: 0 5px;
    font-size: 11px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
