import { SiteOccasionOpeningHours } from '@app/models/site/SiteOccasionOpeningHours';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { DateTimeSplit } from '@app/models/date-time-split.enum';
import { Basket } from '@app/models/basket';
import { OrderOccasion } from '@app/models/order-occasion';
import { Site } from '@app/models/site';
import { IDaysTimeSlots } from '@app/models/wanted-time-picker/IDaysTimeSlots';
import { BasketService } from '@app/api/basket.service';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { OpeningHours } from '@app/models/opening-hours';
import { INewTimeDates } from '@app/models/wanted-time-picker/INewTimeDates';
import { INewTimeSlots } from '@app/models/wanted-time-picker/INewTimeSlots';
import { INewTimeTimes } from '@app/models/wanted-time-picker/INewTimeTimes';
import { lastValueFrom } from 'rxjs';
import { BasketAvailableTime } from '@app/models/basket-available-time';
import { Issue } from '@app/models/Issue';
import { IssueTypes } from '@app/models/issue-types';
import { HttpErrorResponse } from '@angular/common/http';
import { FutureWantedTimePickerComponent } from '@app/shared/components/wanted-time/future-wanted-time-picker/future-wanted-time-picker.component';

@Component({
  imports: [CommonModule, SharedMaterialModule, FutureWantedTimePickerComponent],
  providers: [DatePipe],
  selector: 'app-wanted-time-picker',
  standalone: true,
  styleUrls: ['./wanted-time-picker.component.scss'],
  templateUrl: './wanted-time-picker.component.html'
})
export class WantedTimePickerComponent extends AndroWebCoreComponent implements OnInit, OnChanges {
  @ViewChild('futureTimePicker', { static: true }) private _futureTimePicker: TemplateRef<any>;
  @Output('onWantedTimeUpdated') private _onWantedTimeUpdated: EventEmitter<Basket>;

  @Input() public basket: Basket;
  @Input() public useBasketForInitialTime: boolean = true;
  @Input('site') private _currentSite: Site;
  @Input('showWantedTimeIssue') private _showWantedTimeIssue: boolean = true;

  public timeSlots: INewTimeTimes[];
  public initialTime: string;
  public alertMessages: string[];
  public disableContinueButton: boolean;
  public futureTimeSlots: INewTimeTimes[];
  public wantedTimeIssue: string;
  public isLoading: boolean = true;
  public orText: string = 'or ';

  private readonly _asapStringValue: string = 'A.S.A.P';
  private readonly _tradingDayStartHour: number = 6;
  private readonly _tradingDayStartMinute: number = 30;

  private _orderWantedTime: string;
  private _basketTimeSlots: INewTimeSlots;

  constructor(
    private _basketService: BasketService
  ) {
    super();
    this._onWantedTimeUpdated = new EventEmitter<Basket>();
  }

  ngOnInit() {
    this.setUpAsync();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.basket) {
      return;
    }

    this.checkForWantedTimeIssue();
  }

  /**
   * sets the order wanted time to the selected time
   * @param event
   */
  public orderTimePicked(event: MatSelectChange): void {
    this._orderWantedTime = this.timeSlots.find((x: INewTimeTimes) => x.time === event.value).value;
  }

  /**
   * Orders the basket with the selected time.
   */
  public async orderNow(orderWantedTime?: string): Promise<void> {
    this.isLoading = true;
    const response: Basket | HttpErrorResponse = await this._basketService.updateBasketWantedTime(this.basket.Id, { value: orderWantedTime ?? this._orderWantedTime });

    if (!(response instanceof HttpErrorResponse)) {
      this.basket = response;
    }

    this.isLoading = false;
    this.closeModalById('order-wanted-time-modal');
    this._onWantedTimeUpdated.emit(this.basket);
  }

  /**
  * opens the future date picker modal in a mat dialog
  */
  public openFutureDatePicker(): void {
    this.openDialog(this._futureTimePicker, 'future-wanted-time-modal', { width: this.isMobile ? '100%' : '' });
  }

  /**
   *  updates the `wantedTimeIssue` property.
   */
  private checkForWantedTimeIssue(): void {
    if (!this._showWantedTimeIssue) {
      return;
    }

    if (this.basket.Issues?.some((x: Issue) => x.IssueType === IssueTypes.OccasionIsNotAvailableAtWantedTime)) {
      this.wantedTimeIssue = 'Sorry, your requested time is no longer available, please select a new time.';
    } else if (this.basket.Issues?.some((x: Issue) => [IssueTypes.SiteIsNotAvailableForAsapOrdersNow, IssueTypes.SiteDoesNotAcceptAsapOrdersForOccasion].includes(x.IssueType))) {
      this.wantedTimeIssue = 'Sorry, your requested time is no longer available, please select a new time.';
    } else {
      this.wantedTimeIssue = null;
    }
  }

  /**
   * initial setup for the component.
   */
  private async setUpAsync(): Promise<void> {
    await this.setTimeSlotsAsync();
    this.isLoading = false;

    if (this.timeSlots.length > 0) {
      this.initialTime = this._basketTimeSlots.AllowAsap ? this._asapStringValue : this.timeSlots[0].time;
      this._orderWantedTime = this.initialTime === this._asapStringValue ? null : this.timeSlots[0].value;
    }

    if (!this.basket.WantedTimeUtc || !this.useBasketForInitialTime) {
      return;
    }

    const wantedDate = new Date(this.replaceZuluTime(this.basket.WantedTimeUtc));
    const wantedDateFormatted = this.getStringFromDate(wantedDate, DateTimeSplit.date);
    const wantedTimeFormatted = this.getStringFromDate(wantedDate, DateTimeSplit.time);

    // openFutureDatePicker if wanted date isn't today
    const isAnySlotsToday: boolean = this.timeSlots.some((x: INewTimeTimes) =>
      x.calendarDate === wantedDateFormatted
      && x.time >= wantedTimeFormatted);
    if (!isAnySlotsToday && this.futureTimeSlots.length > 0) {
      this.openFutureDatePicker();
      return;
    }

    this.initialTime = this.getStringFromDate(wantedDate, DateTimeSplit.time);
    this._orderWantedTime = `${wantedDateFormatted}T${this.initialTime}`;
  }

  /**
   * Returns the given date as a string value.
   */
  private async setTimeSlotsAsync(): Promise<void> {
    this.timeSlots = [];
    this.futureTimeSlots = [];
    this.alertMessages = [];
    this._basketTimeSlots = this.getBasketDates(await this._basketService.getTimeSlotsForBasket(this.basket.Id, true));

    if (this._basketTimeSlots.Dates.length === 0 && !this._basketTimeSlots.AllowAsap) {
      this.disableContinueButton = true;

      if (this._currentSite.OpeningHours.length > 0) {
        await this.fallbackToOccasionTimesAsync(this.getStringFromDate(new Date(this._basketTimeSlots.CurrentStoreTimeLocal), DateTimeSplit.date));
        let day: OpeningHours;
        const occasionOpeningTimes = this._currentSite.OccasionOpeningHours?.some((item) => item.OpeningHours?.length > 0);
        const occasionOpeningTimesAllClosed = this._currentSite.OccasionOpeningHours?.every((item) => item.OpeningHours?.length === 0);

        if (this.timeSlots.length === 0) {
          if (occasionOpeningTimes) {
            day = this.findNextAvailableDay(
                this._basketTimeSlots.CurrentStoreTimeLocal,
                this._currentSite.OccasionOpeningHours.flatMap((occasion: SiteOccasionOpeningHours) => occasion.OpeningHours)
            );
          } else {
            day = this.findNextAvailableDay(this._basketTimeSlots.CurrentStoreTimeLocal, this._currentSite.OpeningHours);
          }

          if (this.isAlternativeOccasionAvailable()) {
            this.alertMessages.push(
                `We are not accepting ${this.basket.Occasion} orders at this time${
                  occasionOpeningTimesAllClosed
                    ? `, please consider placing a ${this.getAlternativeOccasion()} order.`
                    : `. Please come back on ${day.Day} from ${this.formatTimeSpanToHhMm(day.StartTime)}, or place a ${this.getAlternativeOccasion()} order.`
                }`);
          } else {
            this.alertMessages.push(
                `We are closed today and do not accept future orders. ${
                  occasionOpeningTimesAllClosed ? '' : `Come back ${day.Day} for ${this.basket.Occasion} from ${this.formatTimeSpanToHhMm(day.StartTime)}.`
                }`
            );
          }
        } else {
          this.disableContinueButton = false;
        }

        return;
      }

      this.alertMessages.push('The restaurant is currently closed.');
      return;
    }

    if (this._basketTimeSlots.AllowAsap) {
      this.timeSlots.push({ calendarDate: null, disabled: false, time: this._asapStringValue, title: `${this._asapStringValue} ${this.getAsapEstimate()}`, value: null });
    }

    if (this._basketTimeSlots.Dates.length === 0) {
      if (this._basketTimeSlots.AllowAsap) {
        await this.fallbackToOccasionTimesAsync(this.getStringFromDate(new Date(this._basketTimeSlots.CurrentStoreTimeLocal), DateTimeSplit.date));
      }
      return;
    }

    this.futureTimeSlots = this._basketTimeSlots.Dates.filter((x: INewTimeDates) => !x.IsCurrentDate && !x.isYesterdayDate).map((x) => x.Times).flat();
    const firstDate: INewTimeDates = this._basketTimeSlots.Dates[0];

    if (firstDate.IsCurrentDate || firstDate.isYesterdayDate) {
      firstDate.Times.filter((x: INewTimeTimes) => !x.notValidForBasket).forEach((x: INewTimeTimes) => this.timeSlots.push(x));

      // merge when trading date is the same (i.e. it's after midnight but before 6am)
      if (firstDate.isYesterdayDate && this._basketTimeSlots.Dates[1]?.IsCurrentDate) {
        this._basketTimeSlots.Dates[1].Times.filter((x: INewTimeTimes) => !x.notValidForBasket).forEach((x: INewTimeTimes) => this.timeSlots.push(x));
      }
      // hack since api only returns calendar date slots for stores configured with no future orders.
      // so stores open past midnight will not have slots for the next calendar day without this hack.
      if (firstDate.IsCurrentDate && this._basketTimeSlots.Dates.length === 1) {
        await this.fallbackToOccasionTimesAsync(firstDate.TradingDate);
      }
    } else if (!this._basketTimeSlots.AllowAsap) {
      this.orText = '';
      if (this.isAlternativeOccasionAvailable()) {
        this.alertMessages.push(`Currently, we are only accepting ${this.getAlternativeOccasion()} orders.
            Alternatively, click below to place a ${this.basket.Occasion} order for a future date.`);
      } else {
        this.alertMessages.push(`We are closed today but you can click below to place a ${this.basket.Occasion} order for a future date.`);
      }
    }
  }

  /**
   * gets availableTimes for the next day from the old api and adds and slots that match the current tradingDate to the timeSlots array.
   * @param currentDate
   */
  private async fallbackToOccasionTimesAsync(tradingDate: string): Promise<void> {
    const nextDate = new Date(tradingDate);
    nextDate.setDate(nextDate.getDate() + 1);
    const calendarDate: string = this.getStringFromDate(nextDate, DateTimeSplit.date);
    const times: BasketAvailableTime[] = await lastValueFrom(this._basketService.getBasketsAvailableTimes(this.basket.Id, nextDate, true));
    times.flatMap((x: BasketAvailableTime) => x.Time)
        .filter((x: string) => x < `0${this._tradingDayStartHour}:${this._tradingDayStartMinute}:00`)
        .forEach((timeSlot: string) => {
          const time: string = this.formatTimeSpanToHhMm(timeSlot);
          const slot: INewTimeTimes = {
            calendarDate,
            time,
            title: time,
            value: `${calendarDate}T${time}`
          };
          this.timeSlots.push(slot);
        });
  }

  /**
   * maps the given response to a more usable format.
   * @param response
   */
  private getBasketDates(response: IDaysTimeSlots): INewTimeSlots {
    // Create a map for easier access to dates
    const datesMap = new Map<string, { TradingDate: string; Times: INewTimeTimes[]; }>();

    // Helper function to format date
    const formatDate = (date: Date) => this.getStringFromDate(date, DateTimeSplit.date);

    // Process each date
    for (const date of response.Dates) {
      const dateObj = new Date(date.Date);
      const tradingDate = formatDate(dateObj);

      // Initialize the date entry if not exists
      if (!datesMap.has(tradingDate)) {
        datesMap.set(tradingDate, { Times: [], TradingDate: tradingDate });
      }

      for (const timeSlot of date.Times) {
        const timeDate = new Date(`${tradingDate}T${timeSlot.Time}`);
        let targetDate = tradingDate;

        // Move times between midnight and trading date end to the previous date
        if (
          timeDate.getHours() < this._tradingDayStartHour
          || (timeDate.getHours() === this._tradingDayStartHour && timeDate.getMinutes() <= this._tradingDayStartMinute)
        ) {
          const previousDay = new Date(dateObj);
          previousDay.setDate(dateObj.getDate() - 1);
          targetDate = formatDate(previousDay);

          if (!datesMap.has(targetDate)) {
            datesMap.set(targetDate, { Times: [], TradingDate: targetDate });
          }
        }

        const time: string = this.formatTimeSpanToHhMm(timeSlot.Time);
        const calendarDate: string = formatDate(timeDate);
        const payload: INewTimeTimes = {
          calendarDate: calendarDate, // Original date before midnight manipulation
          time: time,
          title: time + (timeSlot.SlotFull ? ' Not available' : ''),
          value: `${calendarDate}T${time}`
        };

        if (timeSlot.SlotFull) {
          payload.disabled = true;
        }

        if (timeSlot.NotValidForBasket) {
          payload.notValidForBasket = true;
        }

        // Add the time slot to the appropriate date
        datesMap.get(targetDate).Times.push(payload);
      }
    }

    // Convert the map back to array format
    const dates: INewTimeDates[] = Array.from(datesMap.values())
        .map((dateEntry: { TradingDate: string; Times: INewTimeTimes[]; }) => {
          const result: INewTimeDates = { ...dateEntry };

          const isCurrentDate = dateEntry.TradingDate === formatDate(new Date(response.CurrentStoreTimeLocal));

          if (isCurrentDate) {
            result.IsCurrentDate = true;
          }

          const yesterday = new Date(response.CurrentStoreTimeLocal);
          yesterday.setDate(yesterday.getDate() - 1);
          const isYesterdayDate = dateEntry.TradingDate === formatDate(yesterday);

          if (isYesterdayDate) {
            result.isYesterdayDate = true;
          }

          const nextDay = new Date(response.CurrentStoreTimeLocal);
          nextDay.setDate(nextDay.getDate() + 1);

          const isTomorrowDate = dateEntry.TradingDate === formatDate(nextDay);

          if (isTomorrowDate) {
            result.isTomorrowDate = true;
          }

          return result;
        })
        .filter((x: INewTimeDates) => x.Times.length > 0)
        .sort((a: INewTimeDates, b: INewTimeDates) => new Date(a.TradingDate).getTime() - new Date(b.TradingDate).getTime());

    return {
      AllowAsap: response.AllowAsap,
      CurrentStoreTimeLocal: new Date(response.CurrentStoreTimeLocal),
      Dates: dates
    };
  }

  /**
   * Returns the estimated time for asap orders for the current occasion.
   */
  private getAsapEstimate(): string {
    if (!this._currentSite || !this.basket) {
      return null;
    }

    const time: string = this.basket.Occasion === OrderOccasion.Delivery ? this._currentSite.EstimatedDeliveryTime : this._currentSite.EstimatedCollectionTime;
    const wantedTime: string = time === '00:00:00' ? '00:15:00' : time;
    const splitTime: string[] = wantedTime.split(':');
    return `(estimated ${(Number(splitTime[0]) * 60) + Number(splitTime[1])} mins)`;
  }

  /**
   * Finds the next {OpeningHours} for the given day of the week.
   */
  private findNextAvailableDay(currentDate: Date, openingHours: OpeningHours[]): OpeningHours | undefined {
    // add date so it doesn't show current date (i.e. store was open earlier but has no more slots)
    let currentDay: number = this.getDayOfWeek(currentDate);
    currentDay = currentDay + 1 === 7 ? 0 : currentDay + 1;

    // Sort the openingHours by DayOfWeek to ensure they are in order
    const sortedOpeningHours = openingHours.sort((a, b) => a.DayOfWeek - b.DayOfWeek);

    // Look for the next day in the week
    for (let i = 0; i < sortedOpeningHours.length; i++) {
      if (sortedOpeningHours[i].DayOfWeek >= currentDay) {
        return sortedOpeningHours[i];
      }
    }

    // If not found in the current week, return the first day of the next week
    return sortedOpeningHours[0];
  }

  /**
   * Returns the day of the week for the given date.
   * @param date
   */
  private getDayOfWeek(date: Date): number {
    let day: number = date.getDay() - 1;
    // reset to 6 for sunday
    if (day === -1) {
      day = 6;
    }
    return day;
  }

  /**
   * Formats the given time span to HH:MM format.
   * @param timeSpan
   */
  private formatTimeSpanToHhMm(timeSpan: string): string {
    return timeSpan.substring(0, 5);
  }

  /**
   * Checks availability for the alternative occasion.
   * @param date
   */
  private isAlternativeOccasionAvailable(): boolean {
    if (!this._currentSite.OccasionOpeningHours) {
      return false;
    }

    const currentTime: Date = this._basketTimeSlots.CurrentStoreTimeLocal;
    const currentDay: number = this.getDayOfWeek(currentTime);
    const alternativeOccasionOpeningHours: OpeningHours[] = this._currentSite.OccasionOpeningHours
        .find((x: SiteOccasionOpeningHours) => x.Occasion === this.getAlternativeOccasion())?.OpeningHours
        ?.filter((x: OpeningHours) => x.DayOfWeek === currentDay);

    if (!alternativeOccasionOpeningHours?.length) {
      return false;
    }

    const getOpeningDateTime = (time: string): Date => this.getDateFromString(`${this.getStringFromDate(currentTime, DateTimeSplit.date)}T${time}`);
    return alternativeOccasionOpeningHours
        .some((x: OpeningHours) => x.OpenAllDay === true || (getOpeningDateTime(x.StartTime) <= currentTime && getOpeningDateTime(x.EndTime) >= currentTime));
  }

  /**
   * Gets the alternative occasion.
   * @param date
   */
  private getAlternativeOccasion(): OrderOccasion {
    return this.basket.Occasion === OrderOccasion.Collection ? OrderOccasion.Delivery : OrderOccasion.Collection;
  }
}
