import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '@app/api/user.service';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { FeedbackCategory } from '@app/models/feedback-category';
import { FeedbackSeverity } from '@app/models/feedback-severity';
import { SharedImportsModule } from '@app/shared/shared-imports.module';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'feedback-component',
  styleUrls: ['./feedback.component.scss'],
  templateUrl: './feedback.component.html',
  standalone: true,
  imports: [SharedMaterialModule, SharedImportsModule]
})
export class FeedbackComponent extends AndroWebCoreComponent {
  @Input() public userId: string;
  @Output('close') private _close: EventEmitter<null> = new EventEmitter<null>();

  public isSubmitting: boolean = false;
  public feedbackDetail?: string = null;
  public ratings: number[] = [1, 2, 3, 4, 5];
  public selectedRating?: number = null;
  public submitted: boolean = false;

  constructor(private _userService: UserService) {
    super();
  }

  public close(): void {
    this._close.emit();
  }

  public rate(point: number): void {
    this.selectedRating = point;
  }

  public canSubmit(): boolean {
    return (this.selectedRating > 0 || this.feedbackDetail?.trim().length > 0) && !this.isSubmitting;
  }

  public submit(): void {
    this.isSubmitting = true;

    const finalizeSubmission = () => {
      this.isSubmitting = false;
      this.submitted = true;
    };

    this._userService
        .submitFeedback({
          Category: FeedbackCategory.Application,
          Severity: FeedbackSeverity.Other,
          Detail: this.feedbackDetail || null,
          Rating: this.selectedRating ?? null,
          ContactMethod: 'None'
        }, this.userId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: finalizeSubmission,
          next: finalizeSubmission
        });
  }
}
