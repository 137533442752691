import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { AnalyticsService } from '@app/app-initialisers/analytics-service/analytics.service';
import { Basket } from '@app/models/basket';
import { BasketService } from '@app/api/basket.service';
import { MenuService } from '@app/api/menu.service';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Charge } from '@app/models/Charge';
import { ChargeTypes } from '@app/models/charge-types';
import { IBasketDealIn } from '@app/models/basket/IBasketDealIn';
import { ToastsService } from '@app/shared/services/toasts.service';
import { OrderOccasion } from '@app/models/order-occasion';
import { HttpStatusCodeHandler } from '@app/core/http.status.codes';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ToastTypes } from '@app/models/ToastTypes.enum';
import { SharedImportsModule } from '@app/shared/shared-imports.module';
import { SharedMaterialModule } from '@app/shared/shared-material.module';
import { DealLineItemView } from '@app/models/deals/deal-line-item-view';
import { DealLineView } from '@app/models/deals/deal-line-view';
import { DealView } from '@app/models/deals/deal-view';
import { SelectedDealLineView } from '@app/models/deals/selected-deal-line-view';
import { ProductAddEvent } from '@app/models/product/product-add-event';
import { ProductView } from '@app/models/product/product-view';
import { ProductComponent } from '@app/shared/components/product/product.component';
import { MenuHelperService } from '@app/shared/services/menu-helper/menu-helper.service';
import { combineLatest, debounceTime, takeUntil } from 'rxjs';
import { DealLine } from '@app/models/menu/deal-line';
import { Menu } from '@app/models/menu/menu';
import { Product } from '@app/models/menu/product';
import { Variant } from '@app/models/menu/variant';
import { Deal } from '@app/models/menu/deal';
import { MenuSection } from '@app/models/menu/menu-section';
import { BasketDeal } from '@app/models/basket/basket-deal';
import { BasketItem } from '@app/models/basket/basket-item';
import { MenuDealHelperService } from '@app/shared/services/menu-deal-helper/menu-deal-helper.service';
import { MenuVariantHelperService } from '@app/shared/services/menu-variant-helper/menu-variant-helper.service';
import { getWantedTimeOrLeadTime } from '@app/shared/utils/date-utils';
import { SiteService } from '@app/api/site.service';
import { ListedProductVariant } from '@app/models/menu/listed-product-variant';
import { GoogleAnalyticsItem } from '@app/models/app-initialisers/google-analytics-item';

/**
 * The deal component where deals get view and optionally added to the basket
 * @export
 * @class DealsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-deals',
  styleUrls: ['./deals.component.scss'],
  templateUrl: './deals.component.html',
  standalone: true,
  imports: [SharedMaterialModule, SharedImportsModule, ProductComponent]
})
export class DealsComponent extends AndroWebCoreComponent implements OnInit {
  @ViewChild('CustomiseProductModal') private _customiseProductModal: TemplateRef<any>;
  @Output('close') private _close: EventEmitter<null> = new EventEmitter<null>();
  @Input({ required: true }) private deal: Deal;
  @Input({ required: true }) public occasion: OrderOccasion;
  @Input() public isModal: boolean;
  @Input({ required: true }) public group: string;

  public isLoading: boolean = true;
  public productView: ProductView;
  public deal2: DealView;

  private _productModal: MatDialogRef<any>;

  constructor(
    private _siteService: SiteService,
    private _menuService: MenuService,
    private _basketService: BasketService,
    private _toastsService: ToastsService,
    private _analyticsService: AnalyticsService,
    private _menuHelperService: MenuHelperService,
    private _menuDealHelperService: MenuDealHelperService,
    private _menuVariantHelperService: MenuVariantHelperService
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([this._siteService.currentSite$, this._menuService.menu$, this._basketService.currentBasket$])
        .pipe(
            debounceTime(250),
            takeUntil(this.destroy$)
        )
        .subscribe(([site, menu, basket]) => {
          if (!site || !menu || !this.deal || !basket) {
            return;
          }

          const wantedTimeUtc = getWantedTimeOrLeadTime(basket.Occasion, site, basket);
          this.deal2 = {
            id: this.deal.Id,
            name: this.deal.Name,
            description: this.deal.Description,
            imageBase: this.getMenuImageVisualAsset({ data: this.deal, height: 248 }),
            lines: this.deal.Lines.map((line: DealLine) => this.mapToDealLine(menu, line, wantedTimeUtc)),
            available: this._menuHelperService.availableForOccasion(this.deal.Availability?.Values, OrderOccasion[this.occasion], wantedTimeUtc),
            price: this._menuDealHelperService.getPriceSummaryForDeal(this.deal, OrderOccasion[this.occasion])
          };

          this.trackViewDeal();
          this.isLoading = false;
        });
  }

  /**
  * Expands or collapses a deal line.
  * Upon expansion the selected product is deselected, unless it is the only product in the line and it is a simple product in which case
  * the product will remain selected
  * @param index - the line index
  */
  public toggleLineItem(line2: DealLineView): void {
    line2.expanded = !line2.expanded;

    if (line2.items.length === 1 && this._menuVariantHelperService.isProductSimple(line2.items[0].variants)) {
      return;
    }
  }

  /**
  * Adds a simple product to the deal or opens the product modal if it's a complex product
  * @param lineIndex - the index of line to add the product to
  * @param menuItem - the product to add to the deal
  * @param onInitialise - (optional) prevents the product modal opening up automatically when the deal component is being initialised
  */
  public addProductToDeal(item: DealLineItemView, line: DealLineView): void {
    if (!item.available) {
      return;
    }

    this.productView = null;

    if (!this._menuVariantHelperService.isProductSimple(item.variants)) {
      this.openProductModal(item, line);
      return;
    }

    const lineView: SelectedDealLineView = {
      productId: item.product.Id,
      name: item.product.Name,
      description: item.product.Description,
      payload: {
        Product: { Item: item.variants[0].Id, Quantity: 1 },
        Modifiers: []
      }
    };

    this.updateDealLineItem(line, lineView, false);
  }

  /**
   * Cancels the deal and navigates back to deals page
   */
  public cancelDeal(): void {
    this._close.emit();
  }

  /**
   * Determines whether the deal is valid and if all deal lines have available products selected
   * @return {*}  {boolean}
   */
  public isDealComplete(): boolean {
    if (!this.deal2) {
      return false;
    }

    return this.deal2.available && this.deal2.lines.every((line) => !!line.selectedItem);
  }

  /**
  * Closes the product modal and updates the relevant deal line if a product was selected
  */
  public closeProductModal(): void {
    this.productView = null;
    this._productModal.close();
  }

  /**
  * Adds the deal to the current basket and reroutes the user to the menu page
  */
  public async addDealToCart(): Promise<void> {
    this.isLoading = true;

    const payload = this.getDealInPayload();
    // get all selected items here since add to basket will trigger this.deal2 to be recreated again.
    const selectedLineItems: SelectedDealLineView[] = this.deal2.lines.map((line) => line.selectedItem);
    const response: HttpErrorResponse | HttpResponse<Basket> = await this._basketService.addDealToBasketAsync(payload);

    if (HttpStatusCodeHandler.isSuccessResponse(response)) {
      this._toastsService.emitNotification(this.deal2.name, ToastTypes.success, `${this.deal2.name} has successfully been added to your basket`, 'Yay');
      this.trackAddDealToBasket(response['body'], selectedLineItems);
      this.cancelDeal();
    } else {
      this._toastsService.showToast(ToastTypes.error, 'Could not add item', 'Sorry!');
    }

    this.isLoading = false;
  }

  /**
   * maps the given line to a Deal Line View
   * @param menu
   * @param line
   */
  private mapToDealLine(menu: Menu, line: DealLine, wantedTimeUtc: Date): DealLineView {
    const products = this._menuDealHelperService.getProductVariantsFromList(line.VariantList);
    const productIds: string[] = products.map((p: { product: Product; variants: Variant[]; }) => p.product.Id);

    const lineView: DealLineView = {
      type: line.Type,
      value: line.Value,
      items: products
          .sort((a: ListedProductVariant, b: ListedProductVariant) => (a.product.Sequence ?? 0) - (b.product.Sequence ?? 0))
          .map((p: { product: Product; variants: Variant[]; }) => this.mapToDealLineItemView(p, wantedTimeUtc)),
      webSections: this.getWebSections(menu, productIds),
      sessionId: this.getGuid(),
      expanded: false,
      selectedItem: null
    };

    this.setSelectedItemIfSimple(lineView);

    return lineView;
  }

  /**
   * Maps a product and its variants to a DealLineItemView
   */
  private mapToDealLineItemView({ product, variants }: { product: Product; variants: Variant[]; }, wantedTimeUtc: Date): DealLineItemView {
    return {
      product,
      variants,
      imageBase: this.getMenuImageVisualAsset({ data: product, height: 285 }),
      available: !variants.every((v: Variant) => v.OutOfStock)
        && this._menuVariantHelperService.areVariantsAvailableForOccasion(variants, OrderOccasion[this.occasion], wantedTimeUtc),
      nutritionSummary: this._menuVariantHelperService.getNutritionSummary(variants, false)
    };
  }

  /**
   * Returns the comma-separated names of menu sections containing the products
   */
  private getWebSections(menu: Menu, productIds: string[]): string {
    return menu.Sections
        .filter((section: MenuSection) => section.Products.some((productId: string) => productIds.includes(productId)))
        .map((section: MenuSection) => section.Name)
        .join(', ');
  }

  /**
   * Sets the selectedItem if there is only one item and the product is simple
   */
  private setSelectedItemIfSimple(lineView: DealLineView): void {
    if (lineView.items.length !== 1 || !this._menuVariantHelperService.isProductSimple(lineView.items[0].variants)) {
      return;
    }

    const item: DealLineItemView = lineView.items[0];

    lineView.selectedItem = {
      productId: item.product.Id,
      name: item.product.Name,
      description: item.product.Description,
      payload: {
        Product: {
          Item: item.variants[0].Id,
          Quantity: 1
        },
        Modifiers: []
      }
    };
  }

  /**
   * Updates the deal line item with the selected item and expanded state
   * @param line
   * @param selectedItem
   * @param expanded
   */
  private updateDealLineItem(line: DealLineView, selectedItem: SelectedDealLineView, expanded: boolean): void {
    line.selectedItem = selectedItem;
    line.expanded = expanded;
  }

  /**
   * Opens the product modal for a complex product
   * @param item
   * @param line
   */
  private openProductModal(item: DealLineItemView, line: DealLineView): void {
    this.productView = {
      name: item.product.Name,
      description: item.product.Description,
      imageSource: this.getMenuImageVisualAsset({ data: item.product, height: 244 }),
      isInDeal: true,
      allergens: this._menuHelperService.getAllergenSummary(item.product.Allergens),
      variants: item.variants,
      save: (event: ProductAddEvent) => {
        const lineView: SelectedDealLineView = {
          productId: item.product.Id,
          name: item.product.Name,
          description: item.product.Description,
          payload: event.item
        };
        this.updateDealLineItem(line, lineView, false);
        this._productModal.close();
        return Promise.resolve();
      },
      dealName: this.deal2.name
    };

    const options: MatDialogConfig<void> = {
      autoFocus: false,
      height: this.isMobile ? '100%' : '',
      maxHeight: '100%',
      panelClass: ['custom-dialog-two-container', 'product-modal'],
      width: this.isMobile ? '100%' : '450px'
    };

    this._productModal = this.openDialog(this._customiseProductModal, 'customiseProductModal', options);
  }

  /**
   * returns the deal in payload for the api.
   */
  private getDealInPayload(): IBasketDealIn {
    return {
      DealId: this.deal2.id,
      Items: this.deal2.lines.map((line: DealLineView) => line.selectedItem.payload)
    };
  }

  /**
   * tracks the add/remove deal to basket event.
   */
  private trackAddDealToBasket(basket: Basket, selectedLineItems: SelectedDealLineView[]): void {
    const productIds: string[] = selectedLineItems.map((x) => x.payload.Product.Item);
    const deal: BasketDeal = basket.Deals.find((x) => x.DealId === this.deal2.id && x.Items.every((y) => productIds.includes(y.Product.Item)));
    const price: number = this.calculateCurrentDealPrice(deal);
    this._analyticsService.trackAddToBasket(this.calculateCurrentDealPrice(deal), this.getItemsForAnalytics(price, selectedLineItems));
  }

  /**
   * returns the deal items for analytics.
   */
  private getItemsForAnalytics(price: number, selectedLineItems: SelectedDealLineView[]): GoogleAnalyticsItem[] {
    const items: GoogleAnalyticsItem[] = [
      {
        'item_category': this.group,
        'item_id': this.deal2.id,
        'item_name': this.deal2.name,
        'price': price,
        'quantity': 1
      },
      ...selectedLineItems.map((line: SelectedDealLineView, index: number) => ({
        'item_category': this.deal2.lines[index].webSections,
        'item_id': line.payload.Product.Item,
        'item_name': line.name,
        'coupon': this.deal2.name,
        'quantity': 1
      }))
    ];

    return items;
  }

  /**
   * @returns the total price of a deal
   */
  private calculateCurrentDealPrice(deal: BasketDeal): number {
    let total = this.calculateDealModifiersTotal(deal.Items) ?? 0;

    deal.Items
        .filter((x: BasketItem) => x.Charges?.length > 0)
        .forEach((item: BasketItem) => {
          item.Charges
              .filter((c: Charge) => c.ChargeType === ChargeTypes.ProductCharge)
              .forEach((c: Charge) => total += c.Total);
        });

    return total;
  }

  /**
  * calculates the combined price of all modifiers on a deal
  * @param dealItems - the items (lines) on a deal
  */
  private calculateDealModifiersTotal?(dealItems: BasketItem[]): number {
    let number = 0;

    dealItems?.forEach((item: BasketItem) => {
      item.Charges.filter((c: Charge) => c.ChargeType === ChargeTypes.ChargeableModifier)
          .forEach((c: Charge) => number += c.Total);
    });

    return number;
  }

  private trackViewDeal(): void {
    const item: GoogleAnalyticsItem = {
      item_category: this.group,
      item_id: this.deal.Id,
      item_name: this.deal.Name,
      quantity: 1,
      price: this._menuDealHelperService.getMinPriceForDeal(this.deal, OrderOccasion[this.occasion])
    };

    this._analyticsService.trackViewDeals(this.deal2.id, this.deal2.name, [item]);
  }
}
